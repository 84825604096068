import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles/'
import BackgroundImage from 'gatsby-background-image/'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { Grid } from '@material-ui/core'
import COLORS from '../constants/colors'
import FONTS from '../constants/fonts'
import { shape, string, bool } from 'prop-types'
import Header from '../components/Header'
import { setTextPosition, setBackgroundPosition } from '../utils/banner-slider'
import SIZES from '../constants/sizes'
// import Youtube, { getVideoThumbnail } from '../components/Video/Youtube'
import { Link } from 'gatsby-plugin-intl'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const DEFAULT_BACKGROUND_COLOR = COLORS.COLE
const DEFAULT_TEXT_COLOR = COLORS.WHITE_TEXT

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  slideImage: {
    // height: ({ height }) => height,
    height: '100vh',
    display: 'flex',
    padding: '200px 200px 150px',
    [theme.breakpoints.down('lg')]: {
      padding: '140px 100px 130px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '150px 30px 80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 50px 60px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '100px 20px 60px',
    },
  },
  localVideoWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    padding: '200px 200px 150px',
    color: '#F8F8F8',
    [theme.breakpoints.down('lg')]: {
      padding: '140px 100px 130px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '150px 30px 80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 50px 60px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '100px 20px 60px',
    },
  },
  hiddenCC: { display: 'contents' },
  textContainer: {
    textAlign: 'left',
    padding: '20px 10px ',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
    },
  },
  title: {
    marginBottom: 20,
    fontSize: FONTS.SIZE.XXXL,
    maxWidth: 800,
    direction: 'ltr',
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    '-webkit-text-stroke-width': '0.2px',
    '-webkit-text-stroke-color': 'lightgrey',
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.L,
    },
  },
  description: {
    direction: 'ltr',
    maxWidth: 630,
    fontSize: FONTS.SIZE.M,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XS,
    },
  },
  link: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    fontSize: FONTS.SIZE.S,
    display: ' inline-flex',
    direction: 'ltr',
    marginTop: 20,
    color: 'white',
    textDecoration: 'none',
    '&>svg': {
      fontSize: 15,
    },
  },
  divider: {
    width: SIZES.DIVIDER.WIDTH.XL,
    height: SIZES.DIVIDER.HEIGHT.BOLD,
    backgroundColor: 'currentColor',
    marginTop: 20,
  },
  video: {
    maxWidth: 650,
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
  },
  blank: {
    backgroundColor: COLORS.COLE,
    height: '100vh',
  },
  contentWrapper: {
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'flex-end',
  },
}))

const BannerSlider = ({ data }) => {
  const [sliders, setSliders] = useState([])
  const [height, setHeight] = useState(0)
  const classes = useStyles({ height })

  useEffect(() => {
    const sliders = data.map(
      (
        {
          title,
          description: { description },
          image,
          backgroundPosition,
          textColor,
          textPosition,
          videoLink,
          videoThumbnail,
          backgroundColor,
          linkText,
          linkTarget,
          localVideo,
        },
        i
      ) => {
        return (
          <ConditionalWrapper
            key={i}
            fluid={image ? image.fluid : ''}
            className={classes.slideImage}
            condition={image !== null}
            videoLink={videoLink}
            localVideoUrl={localVideo?.file?.url}
            style={{
              backgroundColor: backgroundColor
                ? backgroundColor
                : DEFAULT_BACKGROUND_COLOR,
              backgroundPosition: backgroundPosition
                ? setBackgroundPosition(backgroundPosition)
                : '',
            }}
          >
            <div className={classes.root}>
              <Grid
                container
                className={classes.contentWrapper}
                spacing={8}
                style={{
                  ...setTextPosition(textPosition),
                  color: textColor ? textColor : DEFAULT_TEXT_COLOR,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={videoLink ? 6 : 12}
                  className={classes.textContainer}
                >
                  <Header className={classes.title}>{title}</Header>
                  <p className={classes.description}>{description}</p>
                  {linkText && linkTarget && (
                    <div>
                      {linkTarget.charAt(0) === '/' ? (
                        <Link to={linkTarget} className={classes.link}>
                          {linkText}
                        </Link>
                      ) : (
                        <a
                          className={classes.link}
                          href={linkTarget}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {linkText}
                          <OpenInNewIcon />
                        </a>
                      )}
                    </div>
                  )}
                  <div className={classes.divider} />
                </Grid>
                {videoLink && (
                  <Grid item xs={12} md={6} className={classes.video}>
                    {/* YOUTUBE DECATIVATED: <Youtube
                    imgFluid={videoThumbnail ? videoThumbnail.fluid : null}
                    videoUrl={videoLink}
                  /> */}
                  </Grid>
                )}
              </Grid>
            </div>
          </ConditionalWrapper>
        )
      }
    )
    const random = Math.floor(Math.random() * data.length)
    const firstSlide = sliders[0]
    sliders[0] = sliders[random]
    sliders[0] = firstSlide
    setSliders(sliders)

    setHeight(window.innerHeight)
    window.addEventListener('resize', () => setHeight(window.innerHeight))
  }, [classes, data])

  const ConditionalWrapper = ({
    condition,
    videoLink,
    localVideoUrl,
    style,
    children,
    ...props
  }) =>
    condition ? (
      <BackgroundImage {...props} style={style}>
        {children}
      </BackgroundImage>
    ) : (
      <div style={{ ...style, position: 'relative', overflow: 'hidden' }}>
        <video
          playsInline
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            objectFit: 'cover',
          }}
        >
          <source src={localVideoUrl} />
          Your browser does not support the video tag.
        </video>
        <div className={classes.localVideoWrapper}>
          <div style={{ flex: 1, maxWidth: '50%' }}>{children}</div>
        </div>
      </div>
      // <div
      //   {...props}
      //   style={
      //     videoLink
      //       ? {
      //           ...style,
      //           display: 'flex',
      //           background: `url(${getVideoThumbnail(
      //             videoLink
      //           )} ) center/cover no-repeat`,
      //         }
      //       : { ...style }
      //   }
      // />
    )
  ConditionalWrapper.propTypes = {
    condition: bool,
    videoLink: string,
    style: shape(),
  }

  return data ? (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      interval={8000}
      useKeyboardArrows
    >
      {/*TODO:Replace index key*/}
      {sliders.length ? sliders : <div className={classes.blank} />}
      {/* {sliders[5] ? sliders[5] : 'Fifth slide does not exist'} */}
    </Carousel>
  ) : (
    <></>
  )
}

// BannerSlider.propTypes = {
//   data: arrayOf(
//     shape({
//       title: string,
//       description: {description: string},
//       textColor: string,
//       textPosition: oneOf(["Bottom left", "Top left", "Bottom right", "Top right"]),
//       image: { fluid: shape() },
//       backgroundColor: string,
//       backgroundPosition: string,
//     })
//   ),
// }

export default BannerSlider
