import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BannerSlider from '../components/BannerSlider'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import COLORS from '../constants/colors'
import FONTS from '../constants/fonts'
import TextArea from '../components/TextArea'
import ImageLink from '../components/ImageLink'
import Divider from '../components/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: COLORS.WHITE,
  },
  bigImageWrapper: {
    width: '100%',
    padding: '0px 40px 0px 40px',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  bgImage: {
    height: '80vh',
    backgroundSize: 'cover',
  },
  bgImageTextWrapper: {
    maxWidth: 750,
    position: 'absolute',
    left: '5%',
    bottom: '10%',
  },
  bigImageText: {
    lineHeight: '1.28',
    fontSize: FONTS.SIZE.M,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XS,
    },
  },
}))

const Home = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down(750))
  const orderedData =
    data.allContentfulGreenMetalsOrderedList.edges[0].node.items
  const bannerContent = orderedData.filter(
    item => item.__typename === 'ContentfulGreenMetalsOrderedList'
  )[0].items
  const textSection1 = orderedData.filter(
    data => data.identifier === 'Home Page Text Section 1'
  )[0]
  const imageTextSection = orderedData.filter(
    data => data.identifier === 'Home Page Image Text Section 1'
  )[0]
  const textSection2 = orderedData.filter(
    data => data.identifier === 'Home Page Text Section 2'
  )[0]

  return (
    <Layout>
      <Seo
        title="Vow Green Metals"
        description="We deliver technology that converts waste and biomaterials into valuable resources and generate clean energy."
        image="./src/images/logo_light_bg.jpg"
        keywords={['Vow Green Metals']}
      />
      <BannerSlider data={bannerContent} />
      <div className={classes.root}>
        <Grid container className={classes.contentContainer}>
          <Divider height={smallScreen ? 20 : 120} />
          <TextArea
            textLeft={textSection1.title}
            textRight={textSection1.description.description}
          />
          <Divider height={smallScreen ? 20 : 120} />
          <ImageLink
            title={imageTextSection.title}
            description={imageTextSection.description.description}
            image={imageTextSection.image.fluid}
            link="/solutions"
          />
          <Divider height={smallScreen ? 20 : 120} />
          <TextArea
            textLeft={textSection2.title}
            textRight={textSection2.description.description}
          />
          <Divider height={smallScreen ? 20 : 120} />
        </Grid>
      </div>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query IndexQuery {
    allContentfulGreenMetalsOrderedList(
      filter: { listId: { eq: "homePageContent" } }
    ) {
      edges {
        node {
          items {
            __typename
            ... on ContentfulGreenMetalsTextSection {
              identifier
              title
              description {
                description
              }
            }
            ... on ContentfulGreenMetalsImageTextSection {
              identifier
              title
              description {
                description
              }
              image {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
            ... on ContentfulGreenMetalsOrderedList {
              items {
                ... on ContentfulGreenMetalsHomeBanner {
                  title
                  description {
                    description
                  }
                  textColor
                  textPosition
                  image {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyContentfulFluid_noBase64
                    }
                  }
                  backgroundPosition
                  videoLink
                  videoThumbnail {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid_noBase64
                    }
                  }
                  backgroundColor
                  linkText
                  linkTarget
                  localVideo {
                    file {
                      url
                      fileName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
