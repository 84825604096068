import React from "react"
import { makeStyles } from "@material-ui/core"
import { Link } from "gatsby-plugin-intl"
import SubHeader from "../components/SubHeader"
import BackgroundImage from "gatsby-background-image"
import arrow from "../images/arrow.png"
import FONTS from "../constants/fonts"

const useStyles = makeStyles(theme => ({
  bodySubHeader: {
    fontSize: FONTS.SIZE.XXXL,
    lineHeight: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: FONTS.SIZE.L,
    },
  },
  bigImageWrapper: {
    width: "100%",
    padding: "0px 40px 0px 40px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  link: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    fontSize: FONTS.SIZE.S,
    display: " inline-flex",
    direction: "ltr",
    marginTop: 20,
    textDecoration: "none",
    "&>svg": {
      fontSize: 15,
    },
  },
  bgImage: {
    height: "80vh",
    backgroundSize: "cover",
  },
  bgImageTextWrapper: {
    maxWidth: 750,
    position: "absolute",
    left: "5%",
    bottom: "10%",
  },
  bigImageText: {
    lineHeight: "1.28",
    fontSize: FONTS.SIZE.M,
    [theme.breakpoints.down("sm")]: {
      fontSize: FONTS.SIZE.XS,
    },
  },
}))

const ImageLink = ({ image, title, description, link }) => {
  const classes = useStyles()

  return (
    <div className={classes.bigImageWrapper}>
      <BackgroundImage
        Tag="section"
        className={classes.bgImage}
        fluid={image}
      >
        <div className={classes.bgImageTextWrapper}>
          <SubHeader className={classes.bodySubHeader}>{title}</SubHeader>
          <p className={classes.bigImageText}>{description}</p>
          <Link to={link} className={classes.link}>
            <img src={arrow} alt="Arrow icon" />
          </Link>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default ImageLink
